<template>
  <div class="app-box">
    <div class="top-box">
      <div class="header">
        <div class="search-input">
          <el-input :placeholder="$t('systemConfig.enterUserName')" v-model="searchKey" @blur="getUsers" @clear="getUsers" clearable></el-input>
        </div>
      </div>
    </div>
    <div class="table-box">
      <edit-user-info
        :drawerVisable.sync="drawerVisable"
        :infoDetail="userInfo"
        :roleData="roleData"
        :departmentData="departmentData"
        :groupUsersData="groupUsersData"
        @save="saveUser"
      />
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="序号">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="workId" label="工号"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="groupName" label="部门">
          <template slot-scope="scope">
            <div>{{ scope.row.groupNames.join() }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="roleNames" label="角色">
          <template slot-scope="scope">
            <div>{{ scope.row.roleNames.join() }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="pmName" label="PM"> </el-table-column>
        <el-table-column prop="boName" label="BO"> </el-table-column>
        <el-table-column prop="directorName" label="总监"> </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <div>{{ scope.row.status ? '启用' : '禁用' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="source" label="来源"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div>
              <el-button v-if="userUpdate" size="mini" @click="editUser(scope.row)">编辑</el-button>
              <span v-else>无操作权限</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        background
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import EditUserInfo from './edit-user-info';
import systemConfigApi from '@/api/modules/systemConfigApi.api.js';

export default {
  name: 'UserManagement',
  components: {
    EditUserInfo,
  },
  computed: {
    userUpdate() {
      return this.$store.state.app.userConfig.permissionsKeys?.UserManagementUpdate;
    },
  },
  data() {
    return {
      searchKey: '',
      drawerVisable: false,
      page: 1,
      pageSize: 10,
      count: 100,
      userInfo: {},
      tableData: [],
      roleData: [],
      departmentData: [],
      groupUsersData: [],
    };
  },
  created() {
    this.getUsers();
    this.getRole();
    this.getDepartment();
    this.getGroupUsers();
  },
  watch: {
    searchKey: function () {
      this.page = 1;
      this.pageSize = 10;
    },
  },
  methods: {
    async getUsers() {
      let params = { page: this.page, pagesize: this.pageSize };
      if (this.searchKey) {
        params.nameCont = this.searchKey;
      }

      const res = await systemConfigApi.users('get', params);
      this.tableData = res?.data?.data?.users || [];
      this.count = res?.data?.data?.count || 0;
    },
    async getGroupUsers() {
      const res = await systemConfigApi.groupUsers();
      const data = res?.data?.data?.groups || [];
      this.groupUsersData = [...data];
    },
    async getRole() {
      let params = { page: 1, pagesize: 99999 };
      const res = await systemConfigApi.roles('get', params);
      if (res && res.status === 200) {
        this.roleData = res?.data?.data?.roles || [];
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getDepartment() {
      let params = { page: 1, pagesize: 99999 };
      if (this.searchKey) {
        params.nameCont = this.searchKey;
      }
      const res = await systemConfigApi.groups('get', params);
      this.departmentData = res?.data?.data?.groups || [];
    },
    editUser(row) {
      this.userInfo = Object.assign({}, row);
      this.drawerVisable = true;
    },
    async saveUser(userInfo) {
      for (let val in userInfo) {
        if (userInfo[val] === '') {
          userInfo[val] = null;
        }
      }
      const res = await systemConfigApi.users('put', userInfo);
      if (res && res.status === 200) {
        this.$message.success('修改成功！');
        this.drawerVisable = false;
        this.getUsers();
      } else {
        this.$message.error(res.data.message);
      }
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getUsers();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getUsers();
    },
  },
};
</script>
<style lang="scss" scoped>
.app-box {
  padding: 18px 16px;
  flex-grow: 1;
  overflow-y: scroll;

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // padding-bottom: 14px;
    // border-bottom: 1px solid #e8eaec;
    height: 51px;
    .header-tit {
      height: 16px;
      line-height: 16px;
      padding-left: 8px;
      border-left: 3px solid#409EFF;
      font-size: 16px;
    }
    .search-input {
      min-width: 300px;
    }
    .header-btns {
      display: flex;
      align-items: center;
      height: 36px;
      font-size: 14px;
      .savebtn {
        margin-left: 16px;
      }
    }
  }

  .top-box,
  .table-box {
    background-color: #fff;
    padding: 10px 24px;
    border-radius: 2px;
    margin-bottom: 18px;
    .edit_from {
      margin-top: 30px;
      display: flex;
      justify-content: flex-start;
      max-width: 1300px;
      min-width: 900px;
      font-size: 14px;

      .tit {
        margin-bottom: 8px;
        i {
          margin-right: 3px;
          color: red;
        }
      }
      .el-form-item {
        margin-bottom: 0;
      }

      .vvww_colOne {
        width: 448px;
      }
      .colOne {
        display: flex;
        flex-direction: column;
        .projectname {
          .el-input {
            width: 100%;
            border-radius: 4px;
            height: 40px;
          }
        }

        .member {
          margin-top: 30px;
          .el-select {
            width: 100%;
            border-radius: 4px;
            height: 40px;
          }
          .multiple {
            margin: 4px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            .el-tag {
              margin: 0px 4px 4px 0;
            }
          }
        }
      }

      .vvww_colTwo {
        width: 320px;
        margin-left: 48px;
      }
      .colTwo {
        display: flex;
        flex-direction: column;
        .director {
          .el-select {
            width: 100%;
            height: 40px;
            border-radius: 4px;
          }
        }

        .time {
          margin-top: 30px;
          ::v-deep .el-date-picker {
            width: 100%;
            border-radius: 4px;
            height: 40px;
          }
        }
      }

      .vvww_colThird {
        width: 320px;
        margin-left: 48px;
      }
      .colThird {
        display: flex;
        flex-direction: column;
        .chief {
          .el-select {
            width: 100%;
            height: 40px;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .bottom-box {
    margin-top: 16px;
    background-color: #fff;
    padding: 20px 24px 32px;
    border-radius: 2px;
  }

  ::v-deep .empty {
    height: 80%;

    .empty__image {
      width: 286px;
      height: 214px;
    }
    &.operation_Management_err {
      .empty__image {
        width: 267px;
        height: 225px;
      }
    }
  }
}
::v-deep.el-table {
  &::before,
  .el-table__fixed::before {
    height: 0;
  }

  //table_header
  .el-table__header-wrapper {
    border: 1px solid #e8eaec;
    .el-table__header th.el-table__cell {
      background-color: #f8f8f8f9;
      color: #515a6e;
      font-weight: 600;
      padding: 6px 0;
      height: 48px;
      box-sizing: border-box;
      border-bottom: 1px solid #e8eaec;
    }
  }

  /*   .el-table__empty-block {
    min-height: 350px;
  } */

  .el-table__body-wrapper {
    transform: translateY(-1px);
  }

  //table_body
  .el-table__row {
    border: 1px solid #dcdee2;
    border-color: #dcdee2;
  }
  .el-table__body td.el-table__cell {
    // cursor: pointer;
    padding: 6px 0;
    height: 40px;
    // border-right: 1px solid #dcdee2;
    border-color: #dcdee2;
    &:first-of-type {
      border-left: 1px solid #dcdee2;
    }
    &:last-of-type {
      border-right: 1px solid #dcdee2;
    }

    .hours-input {
      .el-input__inner {
        color: #515a6e;
        border: none;
        text-align: left;
        height: 29px;
        line-height: 29px;
        background-color: #fff;
      }
    }

    .el-button {
      // width: 126px;
      // height: 20px;
      color: #2d8cf0;
    }
  }
}
.pagination {
  text-align: right;
  margin: 10px;
}
</style>
