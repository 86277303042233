<template>
  <el-drawer id="AddRoleDrawer" :visible.sync="drawerVisable" :before-close="handleClose" :size="drawerSize" :title="$t('systemConfig.editUser')">
    <div class="app-box">
      <div class="top-box">
        <el-form :model="userInfo" ref="userForm" :rules="rules" label-position="top" class="filter-form">
          <el-row>
            <el-col :span="24">{{ this.$t('systemConfig.baseInfo') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="workId" :label="$t('systemConfig.jobNo')">
                <el-input v-model="userInfo.workId" disabled style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="name" :label="$t('systemConfig.name')">
                <el-input v-model="userInfo.name" disabled style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="email" :label="$t('systemConfig.email')">
                <el-input v-model="userInfo.email" disabled style="width: 90%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="groupIds" :label="$t('systemConfig.department')" style="margin-bottom: 10px">
                <el-select v-model="userInfo.groupIds" @change="changeDepartmentSelected" filterable multiple style="width: 90%" collapse-tags>
                  <el-option v-for="item in departmentData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="select-width" prop="status" :label="$t('systemConfig.userStatus')">
                <el-switch v-model="userInfo.status"></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">{{ this.$t('systemConfig.roleInfo') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="roleIds" :label="$t('systemConfig.role')">
                <el-select v-model="userInfo.roleIds" @change="changeRoleSelected" multiple collapse-tags filterable style="width: 90%">
                  <el-option v-for="item in roleData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="multiple">
                <el-tag v-for="(item, idx) in showRolesSelected" @close="handleCloseTag(idx)" :key="idx" closable style="margin-bottom: 10px">
                  {{ item.name }}
                </el-tag>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">{{ this.$t('systemConfig.superiorInfo') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="directorId" :label="$t('systemConfig.director')">
                <el-select v-model="userInfo.directorId" filterable clearable style="width: 90%">
                  <el-option v-for="item in managersData.directors" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="adId" label="Associate Director">
                <el-select v-model="userInfo.adId" filterable clearable style="width: 90%">
                  <el-option v-for="item in managersData.ads" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="boId" label="Senior Manager" filterable>
                <el-select v-model="userInfo.boId" clearable style="width: 90%">
                  <el-option v-for="item in managersData.bos" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="pmId" label="Manager" style="margin-bottom: 10px">
                <el-select v-model="userInfo.pmId" filterable clearable style="width: 90%">
                  <el-option v-for="item in managersData.pms" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="18"></el-col>
          </el-row>
          <el-row>
            <el-col :span="24">{{ this.$t('systemConfig.specialPermissionsConfig') }}</el-col>
          </el-row>
          <el-row class="tree-container">
            <el-col :span="24">
              <el-card body-style="backgroundColor: #fbfbfc" style="border: none">
                <el-input
                  :placeholder="$t('systemConfig.enterName')"
                  v-model="findName"
                  @blur="setCheckedKeys"
                  size="mini"
                  style="width: 100%; padding-bottom: 10px"
                ></el-input>
                <el-tree
                  :data="groupData"
                  :props="defaultProps"
                  show-checkbox
                  :default-checked-keys="defaultChecked"
                  :default-expanded-keys="defaultChecked"
                  node-key="id"
                  ref="tree"
                  highlight-current
                >
                </el-tree>
              </el-card>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="bottom-btns">
        <el-button @click="handleClose" class="createNewbtn" size="medium">{{ $t('systemConfig.cancel') }}</el-button>
        <el-button @click="saveUser" class="savebtn" size="medium" type="primary">{{ $t('systemConfig.ok') }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import systemConfigApi from '@/api/modules/systemConfigApi.api.js';

export default {
  name: 'AddUserDrawer',
  props: {
    drawerVisable: {
      typeof: Boolean,
      default: false,
    },
    infoDetail: {
      typeof: Object,
      default: {},
    },
    roleData: {
      typeof: Array,
      default: [],
    },
    departmentData: {
      typeof: Array,
      default: [],
    },
    groupUsersData: {
      typeof: Array,
      default: [],
    },
  },
  watch: {
    infoDetail: {
      deep: true,
      immediate: false,
      handler(val) {
        this.defaultChecked = [];
        this.groupData = JSON.parse(JSON.stringify(this.groupUsersData));
        this.groupData.forEach(i => {
          i.parentId = i.id;
          i.id = undefined;
        });
        this.userInfo = JSON.parse(JSON.stringify(val));
        this.showRolesSelected = this.roleData.filter(item => val.roleIds.includes(item.id));
        val.groupIds.length && this.getGroupManagers(val.groupIds);
        this.$nextTick(() => {
          if (val.specialPermissions.length) {
            this.defaultChecked = val.specialPermissions.map(item => {
              if (item.type === 'user') {
                return item.id;
              }
            });

            this.defaultChecked = this.defaultChecked.filter(val => val);
          }
        });
      },
    },
  },
  data() {
    return {
      findName: '',
      drawerSize: 1000,
      userInfo: {},
      groupData: [],
      parentNodeData: [],
      showRolesSelected: [],
      defaultChecked: [],
      managersData: {
        bos: [],
        directors: [],
        pms: [],
        ads: [],
      },
      defaultProps: {
        children: 'users',
        label: 'name',
      },

      //校验表单规则
      rules: {
        roleIds: [{ required: true, message: '请选择角色', trigger: 'change' }],
        groupIds: [{ required: true, message: '请选择部门', trigger: 'change' }],
        // directorId: [{ required: true, message: '请选择总监', trigger: 'change' }],
      },
    };
  },
  methods: {
    async getGroupManagers(ids) {
      // console.log('ids', ids);
      const res = await systemConfigApi.groupManagers(ids);
      // console.log('------', res);
      const data = res?.data?.data;
      if (data) {
        this.managersData.bos = data.bos;
        this.managersData.directors = data.directors;
        this.managersData.pms = data.pms;
        this.managersData.ads = data.ads;
      }
      if (!this.managersData.directors.find(item => item.id === this.userInfo.directorId)) {
        this.userInfo.directorId = null;
      }
      if (!this.managersData.bos.find(item => item.id === this.userInfo.boId)) {
        this.userInfo.boId = null;
      }
      if (!this.managersData.pms.find(item => item.id === this.userInfo.pmId)) {
        this.userInfo.pmId = null;
      }
      if (!this.managersData.ads.find(item => item.id === this.userInfo.adId)) {
        this.userInfo.adId = null;
      }
      if (!this.departmentData.find(item => this.userInfo.groupIds.includes(item.id))) {
        this.userInfo.groupIds = null;
      }
    },
    handleClose() {
      const oldInfo = JSON.stringify(this.infoDetail);
      const newInfo = JSON.stringify(this.userInfo);
      if (oldInfo !== newInfo) {
        this.$confirm('修改的数据未保存，关闭会丢失，是否关闭？')
          .then(() => {
            this.$emit('update:drawerVisable', false);
          })
          .catch(() => {});
      } else {
        this.$emit('update:drawerVisable', false);
      }
    },
    handleCloseTag(idx) {
      this.showRolesSelected.splice(idx, 1);
      this.userInfo.roleIds = this.showRolesSelected.map(v => v.id);
    },

    saveUser() {
      this.$refs.userForm.validate(async valid => {
        if (!valid) {
          return;
        }
        const nodes = this.$refs.tree.getCheckedNodes();
        const specialPermissions = nodes.map(v => {
          if (v.type === 'group' && v.parentId) {
            return { id: v.parentId, type: v.type };
          }
          return { id: v.id, type: v.type };
        });
        const { groupIds = null, status = true, roleIds = [], pmId = null, boId = null, directorId = null, id, adId } = this.userInfo;
        const data = { id, groupIds, status, roleIds, pmId, boId, directorId, specialPermissions, adId };
        this.$emit('save', data);
      });
    },
    changeRoleSelected(val) {
      this.showRolesSelected = this.roleData.filter(item => val.includes(item.id));
      this.userInfo.roleIds = this.showRolesSelected.map(v => v.id);
    },
    changeDepartmentSelected(val) {
      this.getGroupManagers(val);
    },
    setCheckedKeys() {
      const user = this.groupData.reduce((pre, cur) => pre.concat(cur.users), []).find(v => v.name.trim() === this.findName);
      if (user) {
        this.$refs.tree.setChecked(user.id, true);
        this.$refs.tree.store.nodesMap[user.id].expanded = true;
        this.$refs.tree.store.nodesMap[user.id].parent.expanded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#AddRoleDrawer {
  ::v-deep .el-drawer__header {
    font-size: 16px;

    font-weight: 700;
    color: #17233d;
    line-height: 24px;
    height: 63px;
    margin: 0;
    border-bottom: 1px solid #e8eaec;
  }
  .app-box {
    padding: 0;
    flex-grow: 1;
    overflow-y: scroll;
    .el-tag {
      margin: 0 4px;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 14px;
      border-bottom: 1px solid #e8eaec;
      // height: 32px;
      padding: 10px 32px;
      font-size: 14px;
      font-weight: 700;
    }

    .top-box {
      width: 100%;
      font-size: 14px;
      background-color: #fff;
      border-radius: 2px;
      font-size: 16px;

      font-weight: 500;
      color: #17233d;
      padding-left: 30px;
      margin-top: 20px;
      // border-top: 1px solid #e9e9e9;
      .el-col-24 {
        padding: 10px 0;
        font-weight: 600;
      }
      .filter-form {
        // display: flex;
        // justify-content: flex-start;
        .left-form {
          display: flex;
          justify-content: flex-start;
          max-width: 1300px;
          min-width: 900px;
          font-size: 14px;
          position: relative;
          .el-form-item {
            margin-bottom: 0;
            ::v-deep .el-form-item__label {
              display: block;
              text-align: left;
              float: none;
            }
          }
          .form-border {
            position: absolute;
            right: 0;
            height: 100%;
            top: 12%;
          }
          .form-border:after {
            content: '';
            position: absolute;
            right: 0;
            height: 250px;
            width: 1px;
            background: #fff;
            opacity: 0.5;
          }
        }
        .right-form {
          margin-top: 30px;
          .el-form-item {
            // margin-bottom: 30px;
            ::v-deep .el-form-item__label {
              display: inline-block;
              text-align: left;
              float: none;
            }
            ::v-deep .el-form-item__content {
              display: inline-block;
            }
          }
          padding-left: 64px;
          display: flex;
          flex-direction: column;
        }
      }
      ::v-deep .el-form-item__error {
        left: 54px;
      }
      .tit {
        margin-bottom: 8px;
        i {
          margin-right: 3px;
          color: red;
        }
      }

      .colStyle {
        // width: 224px;
        padding: 10px 32px;
        margin-right: 56px;
        display: -webkit-box;
        // display: flex;
        // flex-direction: column;
        // flex-wrap: nowrap;
        .el-form-item {
          margin-right: 30px;
          margin-bottom: 0;
          margin-top: 10px;
        }
        .projectname {
          .el-input {
            width: 100%;
            border-radius: 4px;
            height: 40px;
          }
        }
        .member {
          margin-top: 30px;
          .el-select {
            width: 100%;
            border-radius: 4px;
            height: 40px;
          }
          .multiple {
            margin: 4px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            .el-tag {
              margin: 0px 4px 4px 0;
            }
          }
        }
      }
    }

    .bottom-box {
      background-color: #fff;
      padding: 0 24px 32px;
      border-radius: 2px;
      margin-top: 30px;
      height: 600px;
      ::v-deep.el-table {
        &::before,
        .el-table__fixed::before {
          height: 0;
        }

        //table_header
        .el-table__header-wrapper {
          border: 1px solid #e8eaec;
          .el-table__header th.el-table__cell {
            background-color: #f8f8f8f9;
            color: #515a6e;
            font-weight: 600;
            padding: 6px 0;
            height: 48px;
            box-sizing: border-box;
            border-bottom: 1px solid #e8eaec;
          }
        }

        /*    .el-table__empty-block {
          min-height: 350px;
        } */

        .el-table__body-wrapper {
          transform: translateY(-1px);
        }

        //table_body
        .el-table__row {
          border: 1px solid #dcdee2;
          border-color: #dcdee2;
        }
        .el-table__body td.el-table__cell {
          // cursor: pointer;
          padding: 6px 0;
          height: 40px;
          // border-right: 1px solid #dcdee2;
          border-color: #dcdee2;
          .el-checkbox-group {
            display: inline-flex;
            margin-left: 24px;
          }
          &:first-of-type {
            border-left: 1px solid #dcdee2;
          }
          &:last-of-type {
            border-right: 1px solid #dcdee2;
          }

          .hours-input {
            .el-input__inner {
              color: #515a6e;
              border: none;
              text-align: left;
              height: 29px;
              line-height: 29px;
              background-color: #fff;
            }
          }

          .el-button {
            // width: 126px;
            // height: 20px;
            color: #2d8cf0;
          }
        }
      }
    }
    .tree-container {
      .el-col {
        border-radius: 2px;
        padding: 8px;
        background: #fff;
        .el-tree {
          height: 400px;
          overflow: auto;
          // overflow-y: hidden;
          background: #fff;
        }
      }
    }
    .bottom-btns {
      text-align: right;
      margin: 10px 20px;
    }
    ::v-deep .empty {
      height: 80%;

      .empty__image {
        width: 286px;
        height: 214px;
      }
      &.operation_Management_err {
        .empty__image {
          width: 267px;
          height: 225px;
        }
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
::v-deep .el-form-item__label {
  padding-bottom: 0;
  font-weight: normal;
  height: 32px;
  line-height: 32px;
}
::v-deep .el-form-item__error {
  left: 0 !important;
}
::v-deep .el-input__inner {
  height: 32px;
  line-height: 32px;
}
</style>
